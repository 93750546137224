import React, { useState, useEffect } from "react"
import menu6 from '../images/menuscc/menu6new.jpg'

export default function Menucasacorona({}) {

  useEffect(() => {
    window.dataLayer.push({
      brand: "Corona",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
          window.location.search.includes("?cmp=")
              ? window.location.search.split("?cmp=")[1].split("&")[0]
              : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Menu Casa Corona",
      pageType: "Content page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.cervezacorona.com.ar/menucasacorona",
      url_campaign: `${
          window.location.search.includes("utm_campaign=")
              ? window.location.search.split("utm_campaign=")[1]
              : ""
      }`,
      Step_number: "" ,
    })
  }, [])

  return (
    <div style={{height: 'auto', width:'100vw'}}>
      <img src={menu6} alt={'menu6'} style={{height: 'auto', width: '100vw', objectFit: 'contain'}}/>
    </div>
  )
}